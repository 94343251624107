<div *ngIf="!isMobileDevice && (showValidateAccountAction || enableHistoryTab)">
    <h5 class="card-title">Quick Action</h5>
    <div class="container">
        <div class="row">
            <div class="accverif-card" *ngIf="showValidateAccountAction">
                <div class="accverif-card-body">
                    <div class="header-area">
                        <div class="award-checkmark"></div>
                        <button (click)="openModal('validation')">Validate an Account</button>
                    </div>
                    <p class="para-text">Confirm bank account status and beneficial ownership of a bank account.
                    </p>

                </div>
            </div>
            <div class="accverif-card" *ngIf="enableHistoryTab">
                <div class="accverif-card-body">
                    <div class="header-area">
                        <div class="award-checkmark"></div>
                        <button (click)="openModal('history')">View Validation History</button>
                    </div>
                    <p class="para-text">View the history of your account validation requests.</p>
                </div>
            </div>
        </div>
    </div>
</div>