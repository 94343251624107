import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { accountVerificationErrorConfig } from "../account-verification-error.config";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

@Injectable()
export class AccountVerificationUtils {

    constructor(private sanitizer: DomSanitizer) {}

    getErrorMessage(errorCode): string {
        return this.replaceParams(accountVerificationErrorConfig[errorCode], this.getContactMessageParamsMap());
    }

    replaceParams(value: string, params: Map<string, string>): string {
        if (value) {
            params.forEach((val: string, key: string) => {
            const contactKey = '{' + key + '}';
            value = value.replace(contactKey, val);
    
            });
        }
        return value;
    }

    getContactMessageParamsMap(): Map<string, string> {
        const contactMessageMap = new Map();
        contactMessageMap.set('environment.acc_verif_contactMessage.phone1', environment.acc_verif_contactMessage.phone1);
        contactMessageMap.set('environment.acc_verif_contactMessage.phone2', environment.acc_verif_contactMessage.phone2);
        contactMessageMap.set('environment.acc_verif_contactMessage.hours', environment.acc_verif_contactMessage.hours);
        contactMessageMap.set('environment.acc_verif_contactMessage.email', environment.acc_verif_contactMessage.email);
        return contactMessageMap;
    }

    replaceSpacesWithUnderScore(input: string) {
        return input.toUpperCase().replace(/ /g, '_');
    }
    
    removeHyphen(input: string) {
        return input.toUpperCase().replace(/-/g, '');
    }
    
    sanitize(html): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(html);
    }
}