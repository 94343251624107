import { Component, Input } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-verification-view',
  templateUrl: './verification-view.component.html',
  styleUrls: ['./verification-view.component.scss']
})
export class VerificationViewComponent {
  
  selectedTab: string;
  @Input() isHistoryDisabled: any;
  enableHistoryTab: boolean = false;
  public header: any = environment.header;

  constructor(
    private service: ApiService,
    private modal: NgbModal) {
  }

  ngOnInit(): void {

    this.service.currentTab$.subscribe((tab: string) => {
      this.selectedTab = tab;
      console.log(this.selectedTab)
      if (this.selectedTab === 'history') {
        this.service.setEnableHistory(true);
      }
    })

    this.service.enableHistory$.subscribe(value => {
      this.enableHistoryTab = value;
    })
  }

  onHistoryTabClick() {
    if (this.enableHistoryTab) {
      this.selectedTab = 'history';
    }
  }

  onBack(): void {
    this.modal.dismissAll()
  }

}
