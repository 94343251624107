<div [ngClass]="{'alertArea': (message.type === 'success' || message.type === 'error'), 'new-message': message.type === 'token no longer needed for accessOPTIMA login'}"
    *ngIf="message">
    <div
        [ngClass]="{ 'alert': message, 'alert-success': message.type === 'success', 'alert-danger': message.type === 'error', 'alert-message': message.type === 'token no longer needed for accessOPTIMA login'}">
        <div class="alertHeader">{{ message.type }}
            <div class="align-close">
            <div class=" cbds-c-iconButton cbds-c-iconButton--contained close" aria-label="close" (click)="hide()"></div>   
            </div>
        </div>
        <span class="alertContent">
            <div [innerHTML]="message.text"></div>
        </span>
    </div>
</div>