import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sentenceCase'
})
export class SentenceCasePipe implements PipeTransform {

  transform(value: string | null): string {
    if (!value) return '';
    return value.toLowerCase().replace(/(?:^|\s)\S/g, (a) => a.toUpperCase())
  }

}
