import { Injectable } from '@angular/core';
import { Router } from '@angular/router';



const enum ConfirmationInputType {
  Resume = -1,
  Continue = 0,
  Cancel = 1,
}
@Injectable({
  providedIn: 'root'
})
export class TransmitUiHandler {

  constructor(private route: Router) {
    //  super(route);
    // sharedState.getApplicationDataSub('transmitInstance').subscribe(data => {
    //   console.log(data);
    //   this.transmitSDK = data.sdk;
    // })
  }

  handlePolicyRejection(
    title: string,
    text: string,
    buttonText: string,
    failureData: object,
    actionContext: com.ts.mobile.sdk.PolicyAction,
    clientContext: object | null
  ): Promise<com.ts.mobile.sdk.ConfirmationInput> {
    return new Promise((resolve, reject) => {
      const response = com.ts.mobile.sdk.ConfirmationInput.create(
        ConfirmationInputType.Resume
      );
      resolve(response);
    });
  }

  controlOptionForCancellationRequestInSession(
    validOptions: Array<com.ts.mobile.sdk.ControlRequestType>,
    session: com.ts.mobile.sdk.UIAuthenticatorSession<com.ts.mobile.sdk.InputResponseType>
  ): Promise<com.ts.mobile.sdk.ControlRequest> {
    return new Promise((resolve, reject) => {
      const response = com.ts.mobile.sdk.ControlRequest.create(
        com.ts.mobile.sdk.ControlRequestType.CancelAuthenticator
      );
      resolve(response);
    });
  }

  startActivityIndicator(actionContext, clientContext) {
    console.log('start')
  }

  endActivityIndicator(actionContext, clientContext) {
    console.log('end')
  }

  handlePolicyRedirect = function (
    redirectType,
    policyId,
    userId,
    additionalParameters,
    clientContext
  ) {
    return new Promise((resolve, reject) => {
      const response = com.ts.mobile.sdk.RedirectResponseType.RedirectToPolicy;
      const redirectResponse = com.ts.mobile.sdk.RedirectInput.create(response);
      resolve(redirectResponse);
    });
  };

  processJsonData(jsonData, actionContext, clientContext) {
    return new Promise((resolve, reject) => {
      var jsonFromTransmit = JSON.stringify(jsonData);
      resolve(com.ts.mobile.sdk.JsonDataProcessingResult.create(true));
   });
  }

  controlFlowCancelled = function (clientContext) {
    console.log("control flow cancelled");
  };

  controlFlowStarting = function (clientContext) {
    console.log("control flow starting");
  };

  controlFlowEnded = function (error, clientContext) {
    console.log("control flow ended");
  };

  controlFlowActionStarting = function (
    actionContext,
    clientContextl
  ) {
    console.log("Control flow action starting");
  };

  controlFlowActionEnded = function (
    error,
    actionContext,
    clientContext
  ) {
    console.log("Control flow action starting");
  };

  handleAuthenticatorUnregistration = function (
    authenticatorDescription,
    isPlaceholder,
    actionContext,
    clientContext
  ) {
    console.log("handle authentication unregistration");
  };

  selectAuthenticator = function (
    options,
    actionContext,
    clientContext
  ) {
    console.log("select authenticator");
  };

  selectAuthenticatorFallbackAction = function (
    validOptions,
    fallbackAuthenticator,
    session,
    actionContext,
    clientContext
  ) {
    console.log("select authenticator fallback action");
  };

  createPinAuthSession = function (
    title,
    username,
    pinLength
  ) {
    console.log("Pin Authenticator Session");
  };

  createPatternAuthSession = function (
    title,
    username,
    gridWidth,
    gridHeight
  ) {
    console.log("Pattern Authenticator Session");
  };

  createVoiceAuthSession = function (title, username) {
    console.log("Method not implemented.");
  };

  createSecurityQuestionAuthSession = function (
    title,
    username
  ) {
    console.log("create Security Question Auth Session");
  };

  createPlaceholderAuthSession = function (
    placeholderName,
    placeholderType,
    title,
    username,
    authenticatorConfiguredData,
    serverPayload
  ) {
    console.log("Method not implemented by demo application.");
  };

  createFido2AuthSession = function (title, username) {
    console.log("create Fido2 Auth Session");
  };

  getConfirmationInput = function (
    title,
    text,
    continueText,
    cancelText,
    actionContext,
    clientContext
  ) {
    console.log("get Confirmation Input");
  };

  getInformationResponse = function (
    title,
    text,
    continueText,
    actionContext,
    clientContext
  ) {
    console.log("get Information Response");
  };

  createMobileApproveAuthSession = function (
    title,
    username,
    instructions
  ) {
    console.log("create Mobile Approve Auth Session");
  };

  createTicketWaitSession = function (
    actionContext,
    clientContext
  ) {
    console.log("create Ticket Wait Session");
  };

  createTotpAuthSession = function (title, username) {
    console.log("create Totp Auth Session");
  };

  createAuthenticationConfigurationSession = function (
    userId
  ) {
    console.log("create Authentication Configuration Session");
  };

  createRegistrationPromotionSession = function (
    userId,
    actionContext
  ) {
    console.log("create Registration Promotion Session");
  };

  shouldIncludeDisabledAuthenticatorsInMenu = function (
    actionContext,
    clientContext
  ) {
    console.log("should Include Disabled Authenticators In Menu");
  };

  createScanQrSession = function (
    actionContext,
    clientContext
  ) {
    console.log("create Scan Qr Session");
  };

  createFingerprintAuthSession = function (
    title,
    username
  ) {
    console.log("create Fingerprint Auth Session");
  };

  createApprovalsSession = function (userId) {
    console.log("create Approvals Session");
  };

  createTotpGenerationSession = function (
    userId,
    generatorName
  ) {
    console.log("create Totp Generation Session");
  };

  createDeviceManagementSession = function (userId) {
    console.log("create Device Management Session");
  };

  createNativeFaceAuthSession = function (title, username) {
    console.log("create Native Face Auth Session");
  };

  createFaceAuthSession = function (title, username) {
    console.log("create Face Auth Session");
  };

  createDeviceBiometricsAuthSession = function (
    title,
    username
  ) {
    console.log("create Device Biometrics Auth Session");
  };

  localAuthenticatorInvalidated = function (description) {
    console.log("local Authenticator Invalidated");
  };

  setLogEnabled = function (isEnabled) {
    console.log("set Log Enabled");
  };
}
