import { Component, OnInit, ViewChild } from '@angular/core';
import { singleSpaPropsSubject } from 'src/single-spa/single-spa-props';
import { DynamicComp } from './directives/dynamic-comp.directive';
import { AccountVerificationComponent } from './account-verification/account-verification.component';

@Component({
  selector: 'app-account-verification-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'account-verification';
  currentCompName: string = "";
  customClass: string = "";
  shouldDisplayTheComps: boolean = true;
  @ViewChild(DynamicComp, { static: true }) compRef!: DynamicComp;

  compHash: { name: string, compRef: any }[] = [
    { name: "account-verification", compRef: AccountVerificationComponent }
  ];

  ngOnInit() {
    singleSpaPropsSubject.subscribe((props: any) => {
      console.log(props);
    });
  }

  injectComponent() {
    if (!this.currentCompName) return;

    const componentObj = this.compHash.find(comp => comp.name === this.currentCompName);
    if (!componentObj) return;

    const viewRef = this.compRef.viewContainerRef;
    viewRef.clear();
    viewRef.createComponent(componentObj.compRef);
  }
}