import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SpinnerService {

    public isLoading = new BehaviorSubject(false);
    public spinnerText = new BehaviorSubject('');

    showSpinner() {
        this.isLoading.next(true);
    }

    hideSpinner() {
        this.isLoading.next(false);
    }

    setText(spinnerText: string) {
        this.spinnerText.next(spinnerText);
    }

    // For showing no text beneath the spinner
    clearText() {
        this.spinnerText.next('');
    }

}
