import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'maskAccount'
})

export class MaskAccountPipe implements PipeTransform {
    transform(value: any, ...args: any[]) {
        if (!value) {
            return ''
        }
        return value.length > 4 ? '*'.repeat(value.length - 4) + value.slice(-4) : '*'.repeat(value.length);

    }
}