import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

declare var clientLib: any;

@Injectable({
    providedIn: 'root'
})
export class AccountVerificationThreatMetrixService {

    private appName: string;
    private scriptLoaded = false;
    private threatMetrixUrl: string = environment.acc_verif_threatMetrixUrl;
    private threatMetrixProfileDomain: string = environment.acc_verif_threatMetrixProfileDomain;
    private orgId: string = environment.acc_verif_orgId;
    private tmxUnivSessionId: string;

    constructor() {}

    initThreatMetrixService(appName): string {
        this.appName = appName;
        return this.generateTmxUnivSessionId();
    }

    getTmxUnivSessionID() {
    return this.tmxUnivSessionId;
    }

    addEnhancedProfilingScriptTag(tmxUniSessionId): Promise<any> {
        return new Promise((resolve, reject) => {
        if (document.getElementById('threatMetrixUnivEnP') != null) {
            const elem = document.getElementById('threatMetrixUnivEnP');
            elem?.parentElement?.removeChild(elem);
        }
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.id = 'threatMetrixUnivEnP';
        const inlineCode = document.createTextNode(`clientLib.profile("${this.threatMetrixProfileDomain}","${this.orgId}","${tmxUniSessionId}");`);
        script.appendChild(inlineCode);
        document.body.appendChild(script);

        script.onload = () => {
            this.scriptLoaded = true;
            resolve({loaded: true});
        };
        script.onerror = (error: any) => resolve({script: name, loaded: false, status: 'Loaded'});
        });
    }

    generateTmxUnivSessionId() {
        const sessionIdRandom = Math.floor(Math.random() * 100000000);
        const end = this.generateTimeString();
        this.tmxUnivSessionId = `tm-${sessionIdRandom}-${end}`;
        return this.tmxUnivSessionId;
    }

    generateTimeString(scrambled = false): string {
        const dateTime = new Date();
        const mon = dateTime.getMonth();
        const dt = dateTime.getDate();
        const hrs = dateTime.getHours();
        const secs = dateTime.getSeconds();
        const ms = dateTime.getMilliseconds();
        const min = dateTime.getMinutes();
        if (scrambled) {
        return `${ms}-${hrs}-${dt}-${mon}-${secs}-${min}`;
        }
        return `${mon}-${dt}-${hrs}-${min}-${secs}-${ms}`;
    }

}
