import { Component } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { ApiService } from 'src/app/services/api.service';

@Component({
    selector: 'app-account-history',
    templateUrl: './account-history.component.html',
    styleUrls: ['./account-history.component.scss']
})
export class AccountHistoryComponent {
    accountHistory: any;
    public pageIndex: number = 0;
    public currentPageIndex = 0;
    public pageSize = 10;
    showPagination: boolean = false;
    paginatedData: any;
    pageSizeOptions = [10, 20, 50, 100];
    credData: any;
    universalId: any;
    collectionSize: number;

    constructor(
        private service: ApiService
    ) { }

    ngOnInit(): void {
        this.credData = sessionStorage.getItem('credData')
        this.universalId = sessionStorage.getItem('universalId')
        console.log(this.credData);

        const aoCompanyList = JSON.parse(this.credData).results.filter((item: any) => item.applicationName === 'accessoptima').map((item: any) => item.compId);
        this.service.enableHistory$.subscribe(res => {
            if (res) {
                this.onHistoryLoad(aoCompanyList);
            }
        })
    }
    
    onHistoryLoad(aoCompanyList) {
        this.service.checkAccountsHistory(aoCompanyList, this.universalId).subscribe({
            next: (history) => {
                console.log(history)
                if (history && history.data.length) {
                    this.collectionSize = history?.data?.length;
                    const oneYearAgo = new Date();
                    oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

                    // Filter data to only include items from the last 1 year
                    this.accountHistory = history.data.filter(item => {
                        console.log(item.createdDate)
                        const itemDate = new Date(item.createdDate);
                        return itemDate >= oneYearAgo;
                    });
                    console.log(history.data.length, 'history data')
                    if (history.data.length > 10) {
                        this.showPagination = true;
                    }
                }
            },
            error: (error) => {
                console.log("Couldn't retrieve history data", error)
            }
        })
    }

    tablePagination(event: PageEvent) {
        this.currentPageIndex = event.pageIndex;
        this.pageSize = event.pageSize;
    }

    refreshHistory() {
        this.accountHistory = [];
        const aoCompanyList = JSON.parse(this.credData).results.filter((item: any) => item.applicationName === 'accessoptima').map((item: any) => item.compId);
        this.onHistoryLoad(aoCompanyList);
    }

}
