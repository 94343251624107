import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { AccountVerificationComponent } from './account-verification/account-verification.component';
import { AccountValidationComponent } from './account-verification/account-validation/account-validation.component';
import { ModalSpinnerComponent } from './modal-spinner/modal-spinner.component';
import { DynamicComp } from './directives/dynamic-comp.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AlertComponent } from './alert/alert.component';
import { HttpClientModule } from '@angular/common/http';
import { AccountHistoryComponent } from './account-verification/account-history/account-history.component';
import { VerificationViewComponent } from './account-verification/verification-view/verification-view.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { SentenceCasePipe } from './pipes/sentence-case.pipe';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { MaskAccountPipe } from './pipes/mask-account.pipe';
import { AccountVerificationUtils } from './services/account-verification.utils';

@NgModule({
  declarations: [
    AppComponent,
    AccountVerificationComponent,
    AccountValidationComponent,
    ModalSpinnerComponent,
    DynamicComp,
    AlertComponent,
    AccountHistoryComponent,
    VerificationViewComponent,
    SentenceCasePipe,
    MaskAccountPipe
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatSelectModule,
    BrowserAnimationsModule,
    NoopAnimationsModule
   ],
  providers: [AccountVerificationUtils],
  bootstrap: [AppComponent]
})
export class AppModule { }