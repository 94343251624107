import { Injectable } from '@angular/core';
import { TransmitUiHandler } from './transmit-ui-handler';
import { from, Observable } from 'rxjs';
import { sharedState } from '@citizens/mfe-shared-state';

@Injectable({
  providedIn: 'root'
})
export class AccountVerificationTransmitService {
  public transmitSDK!: com.ts.mobile.sdk.TransmitSDKXm;

  constructor(private ssoUiHandler: TransmitUiHandler) {
    sharedState.getApplicationDataSub('transmitInstance').subscribe(data => {
      console.log(data);
      this.transmitSDK = data.sdk;
    });
  }

initializeTransmit(transmitUrl: string): Observable<boolean> {

  const appName = 'web_universal_portal';
  const tokenName = undefined;
  const token = undefined;
  // return com.ts.mobile.sdk.SDKConnectionSettings.create(serverUrl, appName, tokenName, token );

  const settings = this.getTransmitConnectionSettings(transmitUrl, appName);
  this.transmitSDK.setConnectionSettings(settings);

  this.transmitSDK.setUiHandler(this.ssoUiHandler as any); // Using 'any' type due to type definition limitations
  return from(
    this.transmitSDK.initialize().then((result) => {        
      return result;
    })
  );
}

getTransmitConnectionSettings(serverUrl: any, applicationName: any) {
  const appName = applicationName;
  const tokenName = '';
  const token = '';
  return com.ts.mobile.sdk.SDKConnectionSettings.create(serverUrl, appName, tokenName, token);
}

authenticate(universalId, profilingDetails, journeyName, tmxUnivSessionId, profiledUrl): Promise<any> {

  return this.login(universalId, profilingDetails ,journeyName, tmxUnivSessionId, profiledUrl);
}

async login(universalId, profilingDetails, journeyName, tmxUnivSessionId, profiledUrl): Promise<com.ts.mobile.sdk.AuthenticationResult> {

  const clientContext = {
    // uiContainer: document.getElementById(this.TRANSMIT_UI_CONTAINER_ID),
    // viewContainerRef: this.viewContainerRef,
    // loginComp: this.loginComp,
    // passtext: passtext
  };
  const additionalParams = {
    'universal_id': universalId,
    'profiling_details': profilingDetails,
    'tm_session_id': tmxUnivSessionId,
    'profile_Url': profiledUrl
  };

  
  return this.transmitSDK.invokePolicy(journeyName, additionalParams, clientContext);
}

// invokePolicy(journeyName: string, journeyParams: object, clientContext: any): Promise<any> {
//   // this.sessionId = this.cookieService.get('sessionId');
//   const additionalParams = {
//     // sessionid: this.sessionId,
//     // traceid: uuidv4(),
//     ...journeyParams,
//   };

//   return this.transmitSDK.invokePolicy(
//     journeyName,
//     additionalParams,
//     clientContext
//   );
// }
}
